import { useState, useEffect, useCallback } from 'react';
import {useNavigate, useLocation} from "@reach/router";
import Language from "../utils/Language";

const languagesPrefixes = ['en'];

function useLanguage() {
    const [language, setLanguage] = useState(
        (function() {
            const language = Language.getLanguageFromStorage();
            return language ? language : Language.defaultLanguage;
        })()
    );
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const parts = location.pathname.split('/');
        parts.shift();
        const partFirst = parts[0].toLowerCase();
        if (languagesPrefixes.includes(partFirst)) {
            setLanguage(partFirst);
        } else {
            setLanguage('ru');
        }
    }, [location]);

    useEffect(() => {
        if (Language.getLanguageFromStorage() !== language) {
            Language.setLanguageToStorage(language);
        }
    }, [language]);

    const createPath = useCallback( (prefix) => {
        let path = location.pathname;

        if (prefix === '/en' && path.search('/en') === -1) {
            path = `${prefix}${path}`
        } else {
            path = path.replace('/en', '')
        }

        return path;
    }, [location]);

    const changeLanguage = useCallback((newLanguage) => {
        const preparedLanguage = Language.prepareLanguageName(newLanguage);
        setLanguage(preparedLanguage);
        const path = createPath(Language.getLanguageInfoByCode(preparedLanguage).prefix);
        return navigate(path);
    }, [navigate, createPath]);

    return [language, changeLanguage];
}

export default useLanguage;
