import {checkWindow} from "./helperFunctions";

export default class Language {
    static defaultLanguage = 'ru';

    static languages = {
        ru: {
            prefix: '/'
        },
        en: {
            prefix: '/en'
        }
    };
    static keyStorage = 'bank131Language';

    static getLanguageInfoByCode(languageCode) {
        return this.languages[languageCode];
    }

    static getLanguageFromStorage() {
        if (checkWindow()) {
            const itemFromStorage = localStorage.getItem(this.keyStorage);
            if (this.checkLanguage(itemFromStorage)) {
                return itemFromStorage;
            }
        }
        return null;
    }

    static setLanguageToStorage(language) {
        if (checkWindow() && this.checkLanguage(language)) {
            localStorage.setItem(this.keyStorage, language.toLowerCase());
        }
    }

    static checkLanguage(language) {
        return typeof language === 'string' && language && this.languages[language];
    }

    static prepareLanguageName(language) {
        return language.toLowerCase();
    }
}
