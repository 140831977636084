import React from 'react';
import IconsComponent from '../../assets/iconsSVG/symbol/svg/sprite.symbol.svg';

export const IconsSprite = () => {
    return (
        <div
            style={{
                height: 0,
                width: 0,
                overflow: 'hidden'
            }}
        >
            <IconsComponent />
        </div>
    );
};

export default IconsSprite;
