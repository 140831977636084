import React from 'react';
import { YMInitializer } from 'react-yandex-metrika';

import { isBrowser } from '../utils/isBrowser';

const options = {
  accurateTrackBounce: true,
  clickmap: true,
  defer: true,
  trackLinks: true,
  webvisor: true,
};

const YandexMetrika = () => {
  if (!isBrowser || window.yandex_metrika_accounts) {
    return null;
  }

  const ACCOUNT = Number(window.GATSBY_YANDEX_METRICS_ID ?? process.env.GATSBY_YANDEX_METRICS_ID);

  return (
    <YMInitializer accounts={[ACCOUNT]} options={options} />
  );
};

export default YandexMetrika;
