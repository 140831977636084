import React from 'react';
import '../styles/components/cookie.scss';

const Cookie = ({locale}) => {

    const listLanguage = React.useMemo(() => {
        return {
            text: {
                ru: 'Мы используем Cookie',
                en: 'We use Cookie'
            },
            textButton: {
                ru: 'Я не против',
                en: 'Accept'
            }
        }
    }, []);

    const [permission, setPermission] = React.useState(true);

    React.useEffect(() => {
        const perm = localStorage.getItem('bank131CookiePermission');
        if (!perm) {
            setPermission(false);
        }
    }, []);

    const onClickAccess = React.useCallback(() => {
        localStorage.setItem('bank131CookiePermission', new Date().toISOString());
        setPermission(true);
    }, []);

    return (
        <React.Fragment>
            {!permission && (
                <div className={`Cookie ${window.location.pathname === '/pricing' ? "CookieTop" : ""}`}>
                    <div className="Cookie-Inner">
                        <div className="Cookie-Text"><span className="Cookie-Icon" role="img" aria-label="Cookie">🍪</span> {listLanguage.text[locale]}</div>
                        <button className="Cookie-Button" type="button" onClick={onClickAccess}>{listLanguage.textButton[locale]}</button>
                    </div>
                </div>
            )}
        </React.Fragment>
    )
};

export default Cookie;
