export const arraySplitByChunks = (arr, perChunk) => {
    const result = [];

    const wordsPerLine = Math.ceil(arr.length / perChunk);

    for (let line = 0; line < perChunk; line++) {
        for (let i = 0; i < wordsPerLine; i++) {
            const value = arr[i + line * wordsPerLine];
            if (!value) continue;
            if (!Array.isArray(result[line])) {
                result[line] = [];
            }
            result[line].push(value)
        }
    }

    return result;
};

export const checkWindow = () => {
    return typeof window !== 'undefined';
};

export const enAlphabet = () => {
    return ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"]
}
