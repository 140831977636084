import React from 'react';
import PropTypes from 'prop-types';

import '../styles/components/languageSwitcher.scss';
import useLanguage from "../hooks/useLanguage";

const LanguageSwitcher = ({ theme = 'black' }) => {
    const classes = React.useMemo(() => {
        let cls = 'LanguageSwitcher';
        cls += ` LanguageSwitcher_theme_${theme}`;

        return cls;
    }, [theme]);
    const [currentLanguage, setCurrentLanguage] = useLanguage();

    const switchLanguage = React.useCallback((newLanguage) => {
        setCurrentLanguage(newLanguage);
    }, [setCurrentLanguage]);

    return (
        <ul className={classes}>
            <li className="LanguageSwitcher-Cell">
                <button onClick={() => switchLanguage('ru')} className={`LanguageSwitcher-Button ${currentLanguage === 'ru' ? 'active': ''}`}>Ru</button>
            </li>
            <li className="LanguageSwitcher-Cell">
                <button onClick={() => switchLanguage('en')} className={`LanguageSwitcher-Button ${currentLanguage === 'en' ? 'active': ''}`}>En</button>
            </li>
        </ul>
    );
};

LanguageSwitcher.propTypes = {
    theme: PropTypes.oneOf(['black', 'white'])
};

export default LanguageSwitcher;
